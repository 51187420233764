import React from "react"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Breadcrumb } from "components/breadcrumb"

const Agreement = ({ location }) => {
  return (
    <Layout>
      <Seo
        pagetitle="ご利用規約"
        pagepath={location.pathname}
        pagedesc="利用規約 | PARKLoT(パークロット)は「月額制」で「何回でも」実施できるTwitterキャンペーンツールを提供しています。"
      />
      <Breadcrumb title="ご利用規約" />
      <main className="policy">
        <section className="policy__section l-container">
          <h1 className="policy__title">利用規約</h1>
          <p className="policy__text">
            この利用規約（以下「本規約」といいます）は、株式会社X-HACK（以下「当運営者」といいます）が運営する「PARKLoT
            」「PARKLoT
            Lite」「クーポン」「QRコード抽選」「マンガ広告」（以下「本サービス」といいます）の提供を受けるにあたり、必要な基本的事項を定めたものであり、本サービスを利用する利用者全てに適用されるものとします。登録ユーザーの皆さま（以下、「ユーザー」といいます。）には、本規約に従って、本サービスをご利用いただきます。
          </p>
          <h2 className="policy__title2">第1条（適用）</h2>
          <ol className="policy__olist">
            <li className="policy__item">
              本規約は、ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されるものとします。
            </li>
            <li className="policy__item">
              当社は本サービスに関し、本規約のほか、ご利用にあたってのルール等、各種の定め（以下、「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず、本規約の一部を構成するものとします。
            </li>
          </ol>
          <h2 className="policy__title2">第2条（利用登録）</h2>
          <ol className="policy__olist">
            <li className="policy__item">
              本サービスにおいては、登録希望者が本規約に同意の上、当社の定める方法によって利用登録を申請し、当社がこれを承認することによって、利用登録が完了するものとします。
            </li>
            <li className="policy__item">
              当社は、利用登録の申請者に以下の事由があると判断した場合、利用登録の申請を承認しないことがあり、その理由については一切の開示義務を負わないものとします。
              <ul className="policy__list">
                <li className="policy__item">
                  利用登録の申請に際して虚偽の事項を届け出た場合
                </li>
                <li className="policy__item">
                  本規約に違反したことがある者からの申請である場合
                </li>
                <li className="policy__item">
                  その他、当社が利用登録を相当でないと判断した場合
                </li>
              </ul>
            </li>
          </ol>
          <h2 className="policy__title2">
            第3条（ユーザーIDおよびパスワードの管理）
          </h2>
          <ol className="policy__olist">
            <li className="policy__item">
              ユーザーは、自己の責任において、本サービスのユーザーIDおよびパスワードを適切に管理するものとします。
            </li>
            <li className="policy__item">
              ユーザーは、いかなる場合にも、ユーザーIDおよびパスワードを第三者に譲渡または貸与し、もしくは第三者と共用することはできません。当社は、ユーザーIDとパスワードの組み合わせが登録情報と一致してログインされた場合には、そのユーザーIDを登録しているユーザー自身による利用とみなします。
            </li>
            <li className="policy__item">
              ユーザーID及びパスワードが第三者によって使用されたことによって生じた損害は、当社に故意又は重大な過失がある場合を除き、当社は一切の責任を負わないものとします。
            </li>
          </ol>
          <h2 className="policy__title2">第4条（利用料金および支払方法）</h2>
          <ol className="policy__olist">
            <li className="policy__item">
              ユーザーは、本サービスの有料部分の対価として、当社が別途定め、本ウェブサイトに表示する利用料金を、当社が指定する方法により支払うものとします。
            </li>
            <li className="policy__item">
              ユーザーが利用料金の支払を遅滞した場合には、ユーザーは年14．6％の割合による遅延損害金を支払うものとします。
            </li>
            <li className="policy__item">
              ユーザーが利用期間の途中で解約をした場合その他理由の如何を問わず、当社はユーザーから支払を受けた利用料金を返還する義務を負わないものとします。
            </li>
          </ol>
          <h2 className="policy__title2">第5条（禁止事項）</h2>
          <ol className="policy__olist">
            <li className="policy__item">
              法令または公序良俗に違反する行為を禁止します。
            </li>
            <li className="policy__item">
              犯罪行為に関連する行為を禁止します。
            </li>
            <li className="policy__item">
              海外において合法だが、国内では非合法な行為を禁止します。
            </li>
            <li className="policy__item">
              本サービスの内容等、本サービスに含まれる著作権、商標権ほか知的財産権を侵害する行為を禁止します。
            </li>
            <li className="policy__item">
              当社、ほかのユーザー、またはその他第三者のサーバーまたはネットワークの機能を破壊したり、妨害したりする行為を禁止します。
            </li>
            <li className="policy__item">
              本サービスによって得られた情報を商業的に利用する行為を禁止します。
            </li>
            <li className="policy__item">
              サーバー及びネットワークシステムに過度の負担をかけるなど、当社の業務または本サービスの運営を妨害する行為を禁止します。
            </li>
            <li className="policy__item">
              不正アクセスをし、またはこれを試みる行為を禁止します。
            </li>
            <li className="policy__item">
              他のユーザーに関する個人情報等を収集または蓄積する行為を禁止します。
            </li>
            <li className="policy__item">
              不正な目的を持って本サービスを利用する行為を禁止します。
            </li>
            <li className="policy__item">
              本サービスの他のユーザーまたはその他の第三者に不利益、損害、不快感を与える行為を禁止します。
            </li>
            <li className="policy__item">
              他のユーザーに成りすます行為を禁止します。
            </li>
            <li className="policy__item">
              当社が許諾しない本サービス上での宣伝、広告、勧誘、または営業行為
            </li>
            <li className="policy__item">
              面識のない異性との出会いを目的とした行為を禁止します。
            </li>
            <li className="policy__item">
              当社のサービスに関連して、反社会的勢力に対して直接または間接に利益を供与する行為を禁止します。
            </li>
            <li className="policy__item">
              本サービスの機能やリソース等を再販する行為を禁止します。これには、ウェブ制作会社や代理店等が他社のアカウントを運用代行するために本サービスを利用することも含まれます。ウェブ制作会社、PR会社、代理店等が、マルチアカウント機能を用いて、関連性のない他社の複数のアカウントを運用代行するために、本サービスを利用することは、本サービスの機能・リソースの再販にあたるため認められていません。
              原則として、利用者自身が保有しているアカウント、または自社、資本関係等がある関連会社等が保有しているアカウントのみ使用可能です。
            </li>
            <li className="policy__item">
              キャンペーン運用時のTwitter IDの変更は原則禁止しております。
            </li>
            <li className="policy__item">
              キャンペーン運用時の鍵付きアカウントへの変更は原則禁止しております
            </li>
            <li className="policy__item">
              Twitterの禁止事項に該当する行為を行った場合、当運営者は適正な範囲で利用制限・送信防止等の措置を行うことができるものとします。
            </li>
            <li className="policy__item">
              Twitter
              その他本サービスと連携するサービス（以下、「連携サービス」といいます。）の利用規約等の連携サービスの利用に関する規則・ルールに違反する行為
            </li>
            <li className="policy__item">
              その他、当社が不適切と判断する行為を禁止します。
            </li>
            <li className="policy__item">
              利用者が各号に定める行為を行ったことにより当運営者に損害が発生した場合、当運営者は利用者に対し、当該損害の賠償を請求することができるものとします。
            </li>
            <li className="policy__item">
              グロテスク、暴力的、または成人向けコンテンツを含むメディア・キャンペーンを投稿することはできません。強姦及び性的暴行の描写を含むメディア・キャンペーンも許可されていません。
            </li>
            <li className="policy__item">
              本サービスで第三者に公開される情報の中に虚偽の情報を盛り込むことを禁止します。
            </li>
          </ol>
          <h2 className="policy__title2">第6条（本サービスの提供の停止等）</h2>
          <p className="policy__text">
            当社は、以下のいずれかの事由があると判断した場合、ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
          </p>
          <ol className="policy__olist">
            <li className="policy__item">
              本サービスにかかるコンピュータシステムの保守点検または更新を行う場合
            </li>
            <li className="policy__item">
              地震、落雷、火災、停電または天災などの不可抗力により、本サービスの提供が困難となった場合
            </li>
            <li className="policy__item">
              Twitter
              その他の連携サービスに、トラブル、サービス提供の中断または停止、本サービスとの連携の停止、仕様変更等が生じた場合
            </li>
            <li className="policy__item">
              コンピュータまたは通信回線等が事故により停止した場合
            </li>
            <li className="policy__item">
              その他、当社が本サービスの提供が困難と判断した場合
            </li>
            <li className="policy__item">
              当社は、本サービスの提供の停止または中断により、ユーザーまたは第三者が被ったいかなる不利益または損害について、理由を問わず一切の責任を負わないものとします。また、ユーザーは、当社が本条に基づき本サービスの提供の停止または中断をした場合においても利用料金の減額等を請求できないことを認識し、了承するものとします。
            </li>
          </ol>
          <h2 className="policy__title2">第7条（著作権）</h2>
          <ol className="policy__olist">
            <li className="policy__item">
              ユーザーは、自ら著作権等の必要な知的財産権を有するか、または必要な権利者の許諾を得た文章、画像や映像等の情報のみ、本サービスを利用し、投稿または編集することができるものとします。
            </li>
            <li className="policy__item">
              ユーザーが本サービスを利用して投稿または編集した文章、画像、映像その他のデータ（以下、「投稿データ」といいます。）の著作権については、当該ユーザーその他既存の権利者に留保されるものとします。ただし、当社は、本サービスを利用して投稿または編集された文章、画像、映像等を利用（複製、複写、改変、第三者への再許諾その他のあらゆる利用を含みます。）できるものとし、ユーザーは、この利用に関して、著作者人格権を行使しないものとします。
            </li>
            <li className="policy__item">
              Twitter
              前項本文の定めるものを除き、本サービスおよび本サービスに関連する一切の情報についての著作権およびその他知的財産権はすべて当社または当社にその利用を許諾した権利者に帰属し、ユーザーは無断で複製、譲渡、貸与、翻訳、改変、転載、公衆送信（送信可能化を含みます。）、伝送、配布、出版、営業使用、逆アセンブル、逆コンパイル、リバースエンジニアリング等をしてはならないものとします。
            </li>
          </ol>
          <h2 className="policy__title2">第8条（利用制限および登録抹消）</h2>
          <p className="policy__text">
            当社は、ユーザーが以下のいずれかに該当する場合には、事前の通知なく、ユーザーに対して、本サービスの全部もしくは一部の利用を制限し、またはユーザーとしての登録を抹消することができるものとします。
          </p>
          <ol className="policy__olist">
            <li className="policy__item">
              本規約のいずれかの条項に違反した場合
            </li>
            <li className="policy__item">
              登録事項に虚偽の事実があることが判明した場合
            </li>
            <li className="policy__item">
              当社、他のユーザーその他の第三者に損害を生じさせるおそれのある目的または方法で本サービスを利用した、または利用しようとした場合
            </li>
            <li className="policy__item">
              手段の如何を問わず、本サービスの運営を妨害した場合
            </li>
            <li className="policy__item">
              自ら振出し、もしくは引受けた手形もしくは小切手につき、不渡りの処分を受けた場合、または手形交換所の取引停止処分その他これに類する措置を受けたとき
            </li>
            <li className="policy__item">
              差押、仮差押、仮処分、強制執行または競売の申立てがあった場合、または租税公課の滞納処分を受けた場合
            </li>
            <li className="policy__item">
              支払停止もしくは支払不能となり、または破産、民事再生、会社更生または特別清算の手続開始決定等の申立がなされたとき
            </li>
            <li className="policy__item">
              1年間以上本サービスの利用がない場合
            </li>
            <li className="policy__item">
              当社からの問い合わせその他の回答を求める連絡に対して30日間以上応答がない場合
            </li>
            <li className="policy__item">第2条第2項各号に該当する場合</li>
            <li className="policy__item">
              本サービスを通じて公開されたキャンペーン情報内に第三者が明確に認識できない情報があると判断した場合
            </li>
            <li className="policy__item">
              本サービスを通じて公開されたキャンペーン情報が第三者が理解するのに不十分な情報であると判断した場合
            </li>
            <li className="policy__item">
              その他、当社が本サービスの利用を適当でないと判断した場合
            </li>
          </ol>
          <p className="policy__text">
            前項各号のいずれかに該当した場合、ユーザーは、当然に当社に対する一切の債務について期限の利益を失い、その時点において負担する一切の債務を直ちに一括して弁済しなければなりません。
          </p>
          <p className="policy__text">
            当社は、本条に基づき当社が行った行為によりユーザーに生じた損害について、一切の責任を負いません。
          </p>
          <h2 className="policy__title2">第11条（サービス内容の変更等）</h2>
          <p className="policy__text">
            当社は、ユーザーに通知することなく、本サービスの内容を変更しまたは本サービスの提供を中止することができるものとし、これによってユーザーに生じた損害について一切の責任を負いません。
          </p>
          <h2 className="policy__title2">第12条（利用規約の変更）</h2>
          <p className="policy__text">
            当社は、必要と判断した場合には、ユーザーに通知することなくいつでも本規約を変更することができるものとします。なお、本規約の変更後、本サービスの利用を開始した場合には、当該ユーザーは変更後の規約に同意したものとみなします。
          </p>
          <h2 className="policy__title2">第13条（個人情報の取扱い）</h2>
          <p className="policy__text">
            当社・法人・個人は、本サービスの利用によって取得する個人情報については、以下に準じて適切に取り扱うものとします。
          </p>
          <ol className="policy__olist">
            <li className="policy__item">
              当社は、本サービスの利用によって取得する個人情報については、当社「プライバシーポリシー」に従い適切に取り扱うものとします。
            </li>
            <li className="policy__item">
              本サービスを利用する法人は、本サービスの利用によって取得する個人情報については、会員登録時に登録をした法人の明示するプライバシーを記載したリンク先の「プライバシーポリシー」従い適切に取り扱うものとします。
            </li>
            <li className="policy__item">
              本サービスを利用する個人は、本サービスの利用によって取得する個人情報については、当社「プライバシーポリシー」に従い適切に取り扱うものとします。
            </li>
          </ol>
          {/* <h2 className="policy__title2"></h2> */}
          <p className="policy__text">
            ユーザーと当社との間の通知または連絡は、当社の定める方法によって行うものとします。当社は、ユーザーから、当社が別途定める方式に従った変更届け出がない限り、現在登録されている連絡先が有効なものとみなして当該連絡先へ通知または連絡を行い、これらは、発信時にユーザーへ到達したものとみなします。
          </p>
          <h2 className="policy__title2">第15条（準拠法・裁判管轄）</h2>
          <ol className="policy__olist">
            <li className="policy__item">
              本規約は日本法に基づき解釈されるものとします。
            </li>
            <li className="policy__item">
              本サービスに関連して訴訟の必要が生じた場合には、大阪地方裁判所を第一審専属的合意管轄裁判所とします。
            </li>
          </ol>
          <h2 className="policy__title2">第16条（翻訳）</h2>
          <p className="policy__text">
            本規約は日本語を正文とし、他の言語による本規約の翻訳は単なる参照の便宜のためのものであり、効力を有しないものとする。
          </p>
          <br />
          <p className="policy__text">
            ※「QRコード」は株式会社デンソーウェーブの登録商標です。
          </p>
          <div className="policy__revision">
            <p className="policy__text">2023年3月2日 改定</p>
            <p className="policy__text">2020年11月18日 改定</p>
            <p className="policy__text">2020年10月26日 改定</p>
            <p className="policy__text">2020年10月1日 制定</p>
          </div>
        </section>
      </main>
    </Layout>
  )
}

export default Agreement
